<template>
  <base-section id="k-d-a-tech-header" space="0">
    <base-img :src="imageSrc" :style="`${g_bBaseBr ? 'height: 330px' : ''} `" />
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDATechHeader',

  mixins: [BaseBreakpoint],

  computed: {
    imageSrc() {
      return require('@/assets/photos/tech-00.png');
    }
  }
};
</script>
